import React from "react";

const FAQ = () => {
  return (
    <div className="flex flex-col">
      <div className="text-3xl mb-10 md:hidden">FAQ</div>
      <div className="text-xl mb-3">Questions?</div>
      <span>
        For any questions or feedback, please email us at
        <span className="text-primary-700 hover:underline"> support@renthunter.nl </span>
        or use the chatbot :)
      </span>
    </div>
  );
};

export default FAQ;
