import { Route, Routes } from "react-router-dom";

import List from "./list";
import MapPolygonProvider from "./list/provider";
import View from "./view";

const Index = () => {
  return (
    <Routes>
      <Route path="/:id" element={<View />} />
      <Route
        path="/"
        element={
          <MapPolygonProvider>
            <List />
          </MapPolygonProvider>
        }
      />
    </Routes>
  );
};

export default Index;
