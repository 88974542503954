import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import PhoneNumberInput from "../../../components/phoneNumberInput";
import api from "../../../services/api";
import { isPhoneValid } from "../../../utils";

export const UpdateSavedSearchModal = ({ search, onClose }) => {
  const [values, setValues] = useState(null);
  const [whatsappPhone, setWhatsappPhone] = useState(undefined);
  const { user } = useSelector((state) => state.Auth);

  const handleSearchUpdate = async (e) => {
    try {
      e.preventDefault();
      if (!values.notification_type) return toast.error("Please select a notification type");
      if (values.notification_type == "WHATSAPP" && whatsappRequired) {
        if (!whatsappPhone) return toast.error("Please enter your whatsapp phone number");
        if (!isPhoneValid(whatsappPhone)) return toast.error("Please enter a valid whatsapp phone number");
        const res = await api.put("/user", { whatsapp_phone: whatsappPhone });
        if (!res.ok) throw new Error("Error saving phone number");
      }

      const res = await api.put("/user/saved-search/" + search._id, {
        name: values.name,
        notification_type: values.notification_type,
        notification_frequency: values.notification_frequency,
      });
      if (!res.ok) throw new Error("Error updating search");

      toast.success("Search updated");
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    if (!search) setValues(null);
    else
      setValues({
        _id: search._id,
        name: search.name,
        notification_type: search.notification_type,
        notification_frequency: search.notification_frequency,
      });
  }, [search]);

  if (!values) return null;

  const whatsappRequired = !user.whatsapp_phone || user.is_whatsapp_undeliverable;
  return (
    <Modal isOpen={true} onClose={onClose} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[70vh] md:max-h-[calc(100vh-5rem)]">
      <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-lg font-semibold">Update search</div>
          <button
            className="text-2xl"
            onClick={() => {
              onClose();
            }}>
            X
          </button>
        </div>

        <div className="pt-4 md:h-full pb-20 px-8 overflow-auto">
          Save the search to instantly know when new properties are available that match these requirements.
          <form method="POST" onSubmit={handleSearchUpdate}>
            <div className="flex flex-col mt-5">
              <div className="flex flex-wrap sm:flex-nowrap gap-4">
                <div className="w-full md:w-1/2 pr-2">
                  <label className="text-xs text-gray-500">Name your search</label>
                  <input type="text" className="input-primary w-full" value={values.name} onChange={(e) => setValues({ ...values, name: e.target.value })} />
                </div>
              </div>

              <div className="mt-5">Choose how to receive your alerts</div>
              <div className="flex items-center gap-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="email"
                    name="notification_type"
                    value="EMAIL"
                    checked={values.notification_type == "EMAIL"}
                    onChange={(e) => {
                      setValues({ ...values, notification_type: e.target.value });
                    }}
                    className="h-5 w-5 cursor-pointer accent-primary"
                  />
                  <label htmlFor="email" className="p-2 cursor-pointer">
                    Email
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="whatsapp"
                    name="notification_type"
                    value="WHATSAPP"
                    checked={values.notification_type == "WHATSAPP"}
                    onChange={(e) => {
                      setValues({ ...values, notification_type: e.target.value, notification_frequency: "INSTANT" });
                    }}
                    className="h-5 w-5 cursor-pointer accent-primary"
                  />
                  <label htmlFor="whatsapp" className="p-2 cursor-pointer">
                    WhatsApp
                  </label>
                </div>
              </div>
              {values.notification_type == "WHATSAPP" && whatsappRequired && (
                <div className="flex flex-wrap md:flex-nowrap gap-4">
                  <div className="w-full md:w-1/2 pr-2">
                    <label className="text-xs text-gray-500">Whatsapp phone number</label>
                    <PhoneNumberInput phone={whatsappPhone} onChange={(e) => setWhatsappPhone(e)} />
                  </div>
                </div>
              )}
              <div className="mt-5">Choose when to receive your alerts</div>
              <div className="flex items-center gap-4">
                {values.notification_type == "EMAIL" && (
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="daily_summary"
                      name="notification_frequency"
                      value="DAILY"
                      checked={values.notification_frequency == "DAILY"}
                      onChange={(e) => {
                        setValues({ ...values, notification_frequency: e.target.value });
                      }}
                      className="h-5 w-5 cursor-pointer accent-primary"
                    />
                    <label htmlFor="daily_summary" className="p-2 cursor-pointer">
                      Daily (summary)
                    </label>
                  </div>
                )}
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="instant"
                    name="notification_frequency"
                    value="INSTANT"
                    checked={values.notification_frequency == "INSTANT"}
                    onChange={(e) => {
                      setValues({ ...values, notification_frequency: e.target.value });
                    }}
                    className="h-5 w-5 cursor-pointer accent-primary"
                  />
                  <label htmlFor="instant" className="p-2 cursor-pointer">
                    Instantly
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="md:fixed pb-3 inset-x-0 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
          <button type="submit" className="btn-primary w-full" onClick={handleSearchUpdate}>
            Update search
          </button>
        </div>
      </div>
    </Modal>
  );
};
