import { createContext, useContext, useState } from "react";

const MapPolygonContext = createContext({ layers: [], setLayers: () => {} });

const MapPolygonProvider = ({ children }) => {
  /**
   * Layers shape
   * {
   *   "id" : ID,
   *   "layerType": "fill" | "line" | "symbol" | "circle" | "heatmap" | "fill-extrusion" | "raster" | "raster-particle" | "hillshade" | "model" | "background" | "sky" | "slot" | "clip",
   *   "layerPaint": {
   *     "fill-opacity"?: number;
   *	   "fill-color"?: ColorSpecification;
   *	   "fill-outline-color"?: ColorSpecification
   *   }
   *   "geometry": {
   *     "type": "Polygon" | "MultiPolygon",
   *     "coordinates": [coordinates]
   *   }
   * }
   */
  const [layers, setLayers] = useState([]);

  return <MapPolygonContext.Provider value={{ setLayers, layers }}>{children}</MapPolygonContext.Provider>;
};

export default MapPolygonProvider;

export const usePolygon = () => {
  const { layers, setLayers } = useContext(MapPolygonContext);

  const removeAllLayers = () => {
    setLayers([]);
  };

  const updateFillLayer = (name, geometry) => {
    const layer = {
      id: name,
      layerType: "fill",
      layerPaint: {
        "fill-color": "rgb(26,45,83,0.1)",
      },
      geometry,
    };

    setLayers([layer]);
  };

  return {
    layers,
    updateFillLayer,
    removeAllLayers,
  };
};
