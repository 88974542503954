import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { RiArrowDownSLine, RiArrowUpSLine, RiCheckLine, RiCloseLine } from "react-icons/ri";

export const Select = ({
  options,
  value,
  onChange,
  disabled = false,
  width = "w-64",
  position = "left-0 top-full",
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
}) => (
  <Listbox value={value || ""} onChange={onChange} disabled={disabled} by={by}>
    {({ open }) => (
      <>
        <div className="relative w-full">
          <div className="w-full flex items-center justify-start">
            <Listbox.Button className="flex-1 text-left placeholder:text-gray-600 text-sm pl-2">
              <p className="truncate text-left text-sm">Sort by: {getLabel(value)}</p>

              <div className="absolute inset-y-0 right-0 top-[0.17rem] flex items-center">{open ? <RiArrowUpSLine size={20} /> : <RiArrowDownSLine size={20} />}</div>
            </Listbox.Button>{" "}
          </div>

          <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={`absolute z-40 my-1 max-h-52 ${width} ${position} text-base overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
              {options.map((option, i) => (
                <Listbox.Option key={i} value={option} className="select-none list-none">
                  {({ active, selected }) => (
                    <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`}>
                      <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}>{getLabel(option)}</p>
                      {selected && <RiCheckLine />}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);
