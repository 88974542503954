import React from "react";

import { useSelector } from "react-redux";
import { Mixpanel } from "../../services/mixpanel";

const Subscription = () => {
  const { user } = useSelector((state) => state.Auth);

  return user.type == "PAID" ? (
    <>
      <div className="flex flex-col">
        <div className="text-3xl mb-10">Subscription</div>
        <div className="text-xl">Manage my subscription</div>
        <button
          onClick={() => {
            Mixpanel.track("profile_click_customer_portal");
            window.open("https://billing.stripe.com/p/login/5kAdTtfjD0Wf94kcMM", "_blank");
          }}
          className="btn-primary w-fit mt-5">
          Manage my subscription
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col">
        <div className="text-3xl mb-10 md:hidden">Subscription</div>
        <div className="text-xl">You have no active subscription</div>
        <button
          onClick={() => {
            Mixpanel.track("profile_click_upgrade");
            window.open("https://renthunter.nl/pricing", "_blank");
          }}
          className="btn-primary w-full md:w-fit mt-5">
          Start your subscription
        </button>
      </div>
    </>
  );
};

export default Subscription;
