import React, { forwardRef, useMemo, useState } from "react";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { createEmptyHistoryState, HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { $rootTextContent } from "@lexical/text";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { $convertToMentionNodes, BetterMentionNode, BetterMentionsPlugin } from "lexical-better-mentions";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import Modal from "@/components/Modal";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Statsig } from "@/services/statsig";
import { tokens, DEFAULT_MESSAGE_TEMPLATE } from "@/utils/constants";
import { STATSIG_EVENTS } from "@/utils/statsigEvents";

const variables = tokens.map(({ variable }) => variable);

const CustomMessage = () => {
  const { user } = useSelector((state) => state.Auth);
  const { historyState } = useMemo(() => ({ historyState: createEmptyHistoryState() }), []);

  const [isEdited, setIsEdited] = useState(false);
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [customMessage, setCustomMessage] = useState(user?.message_template);

  const betterMentionsTheme = {
    "@": "bg-primary-100",
    "@Focused": "outline-none shadow-md",
  };
  const editorConfig = {
    editorState: user?.message_template
      ? () => {
          $prepopulatedRichText(user?.message_template);
        }
      : undefined,
    nodes: [BetterMentionNode],
    theme: {
      betterMentions: betterMentionsTheme,
    },
    onError: (error) => {
      console.log(error, "error message");
    },
  };

  if (!user) return null;
  return (
    <div className="flex flex-col">
      <VariableListModal isVariableModalOpen={isVariableModalOpen} setIsVariableModalOpen={setIsVariableModalOpen} />

      <div className="text-xl mb-3">Edit your message template</div>
      <span>Save time when contacting landlords with your message template.</span>
      <span>
        Insert details automatically by typing "@" and selecting the relevant variable from the list.{" "}
        <span
          className="ml-1 text-primary underline cursor-pointer hover:text-primary-700 transition-all"
          onClick={() => {
            Statsig.logEvent(STATSIG_EVENTS.account_message_view_variables);
            setIsVariableModalOpen((isOpen) => !isOpen);
          }}>
          see all variables
        </span>
      </span>

      <LexicalComposer initialConfig={editorConfig}>
        <div className="py-4">
          <label className="flex flex-col gap-2">
            <div className="flex flex-col gap-2 text-sm">
              <div className={`relative cursor-text text-base`}>
                <PlainTextPlugin
                  contentEditable={<ContentEditable className={`outline-none min-h-[240px] p-4 border w-full`} />}
                  placeholder={<div className={`absolute p-4 top-0 left-0 w-full pointer-events-none text-gray-500`}>Start writing your custom message...</div>}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <BetterMentionsPlugin
                  triggers={["@"]}
                  onSearch={(trigger, query) => variables.filter((item) => item.toLowerCase().includes(query.toLowerCase()))}
                  items={{ "@": variables }}
                  menuComponent={({ loading, ...props }) => (
                    <ul
                      className="mt-1 max-h-[180px] min-w-40 w-full overflow-y-auto rounded-lg border bg-white relative pointer-events-auto shadow-[2px_2px_4px_rgba(0,0,0,0.08)]"
                      {...props}
                    />
                  )}
                  menuItemComponent={CustomMenuItem}
                />
                {/* This is to enable Undo/Redo functionality (Ctrl + Z, Ctrl + Y) */}
                <HistoryPlugin externalHistoryState={historyState} />
                {/* This is to enable us listen to changes in the editor */}
                <OnChangePlugin
                  onChange={(data, editor) => {
                    let text = editor.getEditorState().read($rootTextContent);
                    setCustomMessage(text);
                    if (!isEdited && customMessage !== text) {
                      setIsEdited(true);
                    }
                  }}
                />
              </div>
            </div>
          </label>
        </div>

        {/* We need the button component within the LexicalComposer component to enable us access the editor */}
        <MessageTemplateButtons isEdited={isEdited} setIsEdited={setIsEdited} customMessage={customMessage} />
      </LexicalComposer>
    </div>
  );
};

const CustomMenuItem = forwardRef(({ selected, item, ...props }, ref) => (
  <div {...props} ref={ref} className={`text-sm py-1 px-5 border-b ${selected ? "bg-gray-100" : "bg-white"}  hover:bg-gray-50`}>
    <div className="cursor-pointer">{item.displayValue}</div>
  </div>
));

// This is here to enable us retrieve the editor from the useLexicalComposerContext hook
const MessageTemplateButtons = ({ isEdited, setIsEdited, customMessage }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const [editor] = useLexicalComposerContext();

  const saveCustomMessage = async (message_template) => {
    try {
      const res = await api.put("/user", { message_template });
      dispatch(setUser(res.data));

      toast.success("Template saved successfully");
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const updateEditor = (message) => {
    editor.update(
      () => {
        $prepopulatedRichText(message, true);
      },
      { discrete: true },
    );

    setIsEdited(false);
  };

  return (
    <div className="pb-3 bottom-0 flex-col-reverse md:flex-row bg-white flex justify-between gap-5 w-full pt-3 md:pb-3">
      <button
        type="button"
        className="btn-secondary"
        onClick={(e) => {
          Statsig.logEvent(STATSIG_EVENTS.account_message_restore_default);
          updateEditor(DEFAULT_MESSAGE_TEMPLATE);
          saveCustomMessage(DEFAULT_MESSAGE_TEMPLATE);
        }}>
        Restore to default
      </button>
      <div className="flex gap-4">
        <button
          type="button"
          className="btn-secondary flex-1 md:flex-initial "
          onClick={(e) => {
            Statsig.logEvent(STATSIG_EVENTS.account_message_cancel_edit);
            updateEditor(user.message_template || DEFAULT_MESSAGE_TEMPLATE);
          }}>
          Cancel
        </button>
        <button
          type="button"
          disabled={!isEdited}
          className="btn-primary w-32 flex-1 md:flex-initial "
          onClick={(e) => {
            Statsig.logEvent(STATSIG_EVENTS.account_message_save);
            updateEditor(customMessage);
            saveCustomMessage(customMessage);
          }}>
          Save
        </button>
      </div>
    </div>
  );
};

const VariableListModal = ({ isVariableModalOpen, setIsVariableModalOpen }) => {
  return (
    <Modal
      innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]"
      isOpen={isVariableModalOpen}
      onClose={() => {
        setIsVariableModalOpen(false);
      }}>
      <div className="w-full max-h-screen h-screen lg:h-auto lg:max-h-[calc(100vh-2rem)] flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-4 py-3 border-b">
          <div className="text-xl font-semibold flex gap-2 items-center">List of variables</div>
          <button
            className="text-2xl"
            onClick={() => {
              setIsVariableModalOpen(false);
            }}>
            X
          </button>
        </div>
        <div className="flex-1 w-full flex flex-col overflow-y-auto">
          <div className="py-4 px-4 mb-24 flex-1">
            <div className="flex flex-col border">
              <div className="grid grid-cols-[minmax(0,2fr)_minmax(0,3fr)] border-b bg-gray-100">
                <div className="p-2 border-r">Variables</div>
                <div className="p-2">Meaning</div>
              </div>
              {tokens.map((row, index) => (
                <div className={`grid grid-cols-[minmax(0,2fr)_minmax(0,3fr)] ${index !== tokens.length - 1 ? "border-b" : ""}`} key={index}>
                  <div className="p-2 border-r">
                    <span className="bg-primary-100 break-words">@{row.variable}</span>
                  </div>
                  <div className="p-2">{row.meaning}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const $prepopulatedRichText = (message, update = false) => {
  const root = $getRoot();

  // If there are currently children in the root node, remove them
  if (update) root.getChildren().forEach((child) => child.remove());

  if (update || root.getFirstChild() === null) {
    const nodes = $convertToMentionNodes(message, ["@"]).map((node) => {
      if (node.getType() === "text") return node; // Return the text node as is

      const value = node.getValue();
      const isValidVariable = variables.includes(value);
      if (isValidVariable) return node; // Only return the mention node if it is a valid variable
      else return $createTextNode(value);
    });
    const paragraph = $createParagraphNode();
    paragraph.append(...nodes);
    root.append(paragraph);
  }
};

export default CustomMessage;
