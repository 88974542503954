const SOURCES = [
  {
    name: "KAMERNET",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Kamernet.nl",
    affiliation_arguments: "",
  },
  {
    name: "FUNDA",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Funda.nl",
    affiliation_arguments: "",
  },
  {
    name: "VBO",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vbo.nl",
    affiliation_arguments: "",
  },
  {
    name: "PARARIUS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Pararius.nl",
    affiliation_arguments: "",
  },
  {
    name: "RENTOLA",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "Rentola.nl",
    affiliation_arguments: "",
  },
  {
    name: "HOUSINGANYWHERE",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "HousingAnywhere.com",
    affiliation_arguments: "",
  },
  {
    name: "KAMER",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Kamer.nl",
    affiliation_arguments: "?utm_medium=affiliate&utm_source=daisycon&utm_campaign=RentHunter",
  },
  {
    name: "HOLLAND2STAY",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Holland2Stay.com",
    affiliation_arguments: "",
  },
  {
    name: "VESTEDA",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vesteda.com",
    affiliation_arguments: "",
  },
  {
    name: "VVAAMSTERDAM",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Vvaamsterdam.nl",
    affiliation_arguments: "",
  },
  {
    name: "THEHOMELIKE",
    trusted: true,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "TheHomelike.com",
    affiliation_arguments: "",
  },
  {
    name: "123WONEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "123Wonen.nl",
    affiliation_arguments: "",
  },
  {
    name: "IKWILHUREN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "IkWilHuren.nu",
    affiliation_arguments: "",
  },
  {
    name: "WOONNETRIJNMOND",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Woonnetrijnmond.nl",
    affiliation_arguments: "",
  },
  {
    name: "VERHUURTBETER",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Verhuurtbeter.nl",
    affiliation_arguments: "",
  },
  {
    name: "IAMEXPAT",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: false,
    readable_name: "IamExpat.nl",
    affiliation_arguments: "",
  },
  {
    name: "ROOFZ",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Roofz.nl",
    affiliation_arguments: "",
  },
  {
    name: "VANDERLINDEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "VanderLinden.nl",
    affiliation_arguments: "",
  },
  {
    name: "NEDERWOON",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "NederWoon.nl",
    affiliation_arguments: "",
  },
  {
    name: "AMSTERDAM.MIJNDAK",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Amsterdam.Mijndak.nl",
    affiliation_arguments: "",
  },
  {
    name: "HOUSEHUNTING",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "HouseHunting.nl",
    affiliation_arguments: "",
  },
  {
    name: "HUURWONINGEN",
    trusted: false,
    has_registration: false,
    default_type: null,
    paid: true,
    readable_name: "Huurwoningen.nl",
    affiliation_arguments: "",
  },
  {
    name: "SCHEPVASTGOEDMANAGERS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "SchepVastgoedmanagers.nl",
    affiliation_arguments: "",
  },
  {
    name: "INTERHOUSE",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Interhouse.nl",
    affiliation_arguments: "",
  },
  {
    name: "HOUSINGNET",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "HousingNet.nl",
    affiliation_arguments: "",
  },
  {
    name: "AMSTERDAMWONEN",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "AmsterdamWonen.nl",
    affiliation_arguments: "",
  },
  {
    name: "VBTVERHUURMAKELAARS",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "VbtVerhuurmakelaars.nl",
    affiliation_arguments: "",
  },
  {
    name: "WOONZEKER",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Woonzeker.nl",
    affiliation_arguments: "",
  },
  {
    name: "REBOHUURWONING",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "ReboHuurwoning.nl",
    affiliation_arguments: "",
  },
  {
    name: "IK-ZOEK.DE-ALLIANTIE",
    trusted: true,
    has_registration: true,
    default_type: "AGENCY",
    paid: false,
    readable_name: "Ik-Zoek.De-Alliantie.nl",
    affiliation_arguments: "",
  },
];

export const TRUSTED_SOURCES = SOURCES.filter((source) => source.trusted).map((source) => source.name);

export const PAID_SOURCES = SOURCES.filter((source) => source.paid).map((source) => source.name);

export const SOURCES_TO_READABLE = SOURCES.reduce((acc, source) => {
  acc[source.name] = source.readable_name;
  return acc;
}, {});

export const tokens = [
  // { variable: "advertName", meaning: "The name of the rental listing." },
  // { variable: "advertStreet", meaning: "The street or address of the rental property." },
  { variable: "advertCity", meaning: "The city where the rental property is located, ex: Amsterdam" },
  { variable: "advertPrice", meaning: "The listed price for the rental, ex: 750€" },
  { variable: "firstName", meaning: "Your first name." },
  { variable: "lastName", meaning: "Your last name." },
  { variable: "email", meaning: "Your email address." },
  { variable: "phoneNumber", meaning: "Your contact phone number." },
  // { variable: "moveInDate", meaning: "Desired move-in date." },
];
export const SOURCES_AFFILIATION_ARGUMENTS = SOURCES.reduce((acc, source) => {
  acc[source.name] = source.affiliation_arguments;
  return acc;
}, {});

export const DEFAULT_MESSAGE_TEMPLATE = `Dear owner,
My name is @firstName @lastName and I’d like to schedule a viewing. Any date and time are fine. I can attend in-person and/or online.

Let me introduce myself:
[INSERT A BRIEF DESCRIPTION HERE, FOCUSING ON YOUR BACKGROUND, PURPOSE FOR MOVING, AND WHY THIS PROPERTY IS IDEAL FOR YOU]

Key details:
- I work as a Software Engineer at Booking.com / I am studying Psychology at UvA / I am a freelance writer
- Annual gross income: €40,000
- Savings: €10,000
- Sponsors’ annual gross income: €55,000
- I will live alone
- I would like to stay for 12+ months

No pets; no kids; no smoking.

All necessary documents are prepared. Looking forward to scheduling a viewing soon.

Best regards,
@firstName @lastName
@phoneNumber
`;
