export const STATSIG_EVENTS = {
  advert_card_click: "advert_card_click",
  add_favorite: "add_favorite",
  remove_favorite: "remove_favorite",

  advert_page_click_contact_landlord: "advert_page_click_contact_landlord",
  advert_page_click_edit_message_template: "advert_page_click_edit_message_template",
  advert_page_click_copy_message_and_redirect: "advert_page_click_copy_message_and_redirect",

  account_message_view_variables: "account_message_view_variables",
  account_message_cancel_edit: "account_message_cancel_edit",
  account_message_restore_default: "account_message_restore_default",
  account_message_save: "account_message_save",
};
