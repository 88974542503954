import { StatsigClient, StatsigOptions, StatsigUser } from "@statsig/js-client";
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
import { StatsigAutoCapturePlugin } from "@statsig/web-analytics";

import { ENVIRONMENT } from "../config";

const GENERATED_ID_STATSSIG_KEY = "generatedIdForStatssig";

const generateRandomId = () => {
  return Math.random().toString(36);
}

const storeGeneratedIdToLocalStorage = (id) => {
  localStorage.setItem(GENERATED_ID_STATSSIG_KEY, id);
};

const getGeneratedIdFromLocalStorage = () => {
  return localStorage.getItem(GENERATED_ID_STATSSIG_KEY);
};

const upsertGeneratedId = () => {
  let id = getGeneratedIdFromLocalStorage();
  if (!id) {
    id = generateRandomId();
    storeGeneratedIdToLocalStorage(id);
  }
  return id;
};

// We use stableID as the user identifier for Statsig, randomly generated if not already set
// When the user logs in, we update the user with the actual userID
const user = { stableID: upsertGeneratedId() };

const options = {
  plugins: [new StatsigSessionReplayPlugin(), new StatsigAutoCapturePlugin()],
  environment: { tier: ENVIRONMENT },
};

const myStatsigClient = new StatsigClient("client-i8vRe8FToKIt7q7HrxQLahtnOwAdqIxNck3zW3XhIVf", user, options);

// TEST init sync
myStatsigClient.initializeSync();

let actions = {
  updateUserSync: (user) => {
    myStatsigClient.updateUserSync(user);
  },
  getExperiment: (experimentName) => {
    return myStatsigClient.getExperiment(experimentName);
  },
  checkGate: (gateName) => {
    return myStatsigClient.checkGate(gateName);
  },
  logEvent: (eventName, value, metadata) => {
    myStatsigClient.logEvent({ eventName, value, metadata });
  },
};

export let Statsig = actions;
