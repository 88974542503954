import React, { useState, useEffect, useRef, Fragment } from "react";

import { Combobox as HLCombobox, Transition } from "@headlessui/react";
import { CiSearch } from "react-icons/ci";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";

export const Combobox = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = true,
  placeholder = "Sélectionner une option",
  width = "w-full",
  position = "left-0",
  listHeader,
  onInputChange = () => {},
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
  match = (o, s) => getLabel(o).toLowerCase().includes(s.toLowerCase()),
  displayValue = (o) => (multiple && o[0] ? getLabel(o[0]) + (o.length > 1 ? ` +${o.length - 1} ` : "") : getLabel(o)),
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const debounceTimeout = useRef(null); // Ref to store the debounce timeout

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const filteredOptions = options.filter((option) => match(option, value));
    setFilteredOptions(filteredOptions);

    // Clear any existing timeout to debounce
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    // Set a new debounce timeout
    debounceTimeout.current = setTimeout(() => {
      onInputChange(value);
    }, 300); // Debounce delay of 300ms, you can adjust this delay as needed
  };

  return (
    <HLCombobox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
      {() => (
        <>
          <div className="relative w-full">
            <div className="w-full flex gap-4 items-center justify-between border border-gray-300 rounded-md overflow-hidden hover:border-primary">
              <HLCombobox.Button className="w-full">
                <div className={`top-0 h-full absolute inset-y-0 left-0 flex items-center px-2 ${disabled ? "opacity-50" : ""}`}>
                  <CiSearch size={22} />
                </div>
                {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable ? (
                  <div className="h-full absolute top-0 right-0">
                    <div
                      className={`absolute inset-y-0 right-0 flex items-center pr-2 ${disabled ? "opacity-50" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(multiple ? [] : "");
                      }}>
                      <RiCloseLine />
                    </div>
                  </div>
                ) : null}
                <HLCombobox.Input
                  className="w-full bg-[#F0F2F8] border-none flex-1 text-left placeholder:text-black placeholder:font-light text-sm py-2 pl-9"
                  displayValue={displayValue}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                />
              </HLCombobox.Button>
            </div>

            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <HLCombobox.Options
                className={`absolute z-20 my-1 max-h-64 ${width} ${position} overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                {listHeader}

                {filteredOptions.length === 0 ? (
                  <HLCombobox.Option className="px-3 py-2 flex items-center justify-between">
                    <p className={`block truncate text-sm text-gray-400`}>No results</p>
                  </HLCombobox.Option>
                ) : (
                  filteredOptions.map((option, i) => (
                    <HLCombobox.Option key={i} value={option} className="select-none list-none">
                      {({ active, selected }) => (
                        <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`} title={getLabel(option)}>
                          <p className={`block truncate text-sm ${selected ? "font-semibold" : "font-normal"}`}> {getLabel(option)} </p>
                          {selected && <RiCheckLine />}
                        </div>
                      )}
                    </HLCombobox.Option>
                  ))
                )}
              </HLCombobox.Options>
            </Transition>
          </div>
        </>
      )}
    </HLCombobox>
  );
};
