import React from "react";
import { Helmet } from "react-helmet";
import { ENVIRONMENT } from "../config";

const Gtm = () => {
  if (ENVIRONMENT !== "production") {
    console.log("do not init GTM", { ENVIRONMENT });
    return <div />;
  }

  return (
    <Helmet>
      <script>
        {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s);
            j.async=true;
            j.src='https://gtm.renthunter.nl/gtm.js?id='+i+(l!='dataLayer'?'&l='+l:'');
            if(f && f.parentNode){
              f.parentNode.insertBefore(j,f);
            } else {
              d.getElementsByTagName('head')[0].appendChild(j);
            }
          })(window,document,'script','dataLayer','GTM-T6Z92JD2');
        `}
      </script>
    </Helmet>
  );
};

export default Gtm;
