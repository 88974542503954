import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AiOutlineFieldTime } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import { FaRegEdit } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";

import ActionsMenu from "@/components/ActionsMenu";
import { capitalizeFirstLetter } from "@/utils";
import { Combobox } from "../../components/Combobox";
import Loader from "../../components/Loader";
import Toggle from "../../components/Toggle";
import { setUser } from "../../redux/auth/actions";
import api from "../../services/api";
import { Mixpanel } from "../../services/mixpanel";
import { SOURCES_TO_READABLE } from "../../utils/constants";
import { UpdateSavedSearchModal } from "../housingMatches/list/updateSavedSearchModal";
import EditSearchModal from "./edit";

const Searches = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newSearch, setNewSearch] = useState({ municipality: "" });
  const [searches, setSearches] = useState(null);
  const [totalMatchesForSearches, setTotalMatchesForSearches] = useState({});
  const [estimateMatchesAWeek, setEstimateMatchesAWeek] = useState(null);
  const [editSearch, setEditSearch] = useState(null);
  const [editSearchAlert, setEditSearchAlert] = useState(null);
  const [municipalities, setMunicipalities] = useState([]);

  const fetchSearches = async () => {
    try {
      const res = await api.get("/user");
      if (!res.ok) throw new Error("Error");
      setSearches(res.data.saved_searches);
      dispatch(setUser(res.data));
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const fetchTotalMatchesForSearches = async () => {
    try {
      if (!searches) return;
      const totalMatchesForSearches = {};
      let estimateMatchesAWeek = 0;
      for (let search of searches) {
        if (!search.isActivated) continue;
        const res = await api.post("/advert/search", { ...search, totalOnly: 1 });
        if (!res.ok) throw new Error("Error");
        totalMatchesForSearches[search._id] = res.total;
        estimateMatchesAWeek += res.totalLastWeek;
      }
      setTotalMatchesForSearches(totalMatchesForSearches);
      setEstimateMatchesAWeek(estimateMatchesAWeek);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const fetchMunicipalities = async () => {
    try {
      const res = await api.get("/municipality");
      setMunicipalities(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    fetchSearches();
    fetchMunicipalities();
  }, []);

  useEffect(() => {
    fetchTotalMatchesForSearches();
  }, [searches]);

  const removeSearch = async (_id) => {
    try {
      const res = await api.delete("/user/saved-search/" + _id);
      if (!res.ok) throw new Error("Error");
      toast.success("Search deleted");
      Mixpanel.track("home_delete_search_click");
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  return (
    <>
      <EditSearchModal
        search={editSearch}
        municipalities={municipalities}
        onClose={() => {
          fetchSearches();
          setEditSearch(null);
        }}
      />
      <UpdateSavedSearchModal
        search={editSearchAlert}
        onClose={() => {
          fetchSearches();
          setEditSearchAlert(null);
        }}
      />
      <div className="p-8">
        <div className="flex justify-between items-start w-full">
          <div className="text-3xl mb-10 leading-none">My Alerts</div>
          <button
            className="btn-primary"
            onClick={() => {
              navigate("/");
              Mixpanel.track("home_add_search_click");
            }}>
            Add search
          </button>
        </div>
        {!searches || !municipalities ? (
          <Loader />
        ) : (
          <>
            {searches.length == 0 && (
              <div className="flex flex-col justify-center items-center gap-5">
                <div className="text-xl text-center md:mt-10">You don't have any search alerts, create one now!</div>

                <div className="w-full md:w-2/3 lg:w-1/2 flex flex-col md:flex-row items-center justify-center gap-3">
                  <Combobox
                    value={municipalities.find((municipality) => municipality.name == newSearch.municipality)}
                    options={municipalities}
                    onChange={(municipality) => {
                      setNewSearch({
                        ...newSearch,
                        municipality: municipality.name,
                      });
                    }}
                    getLabel={(e) => (e ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : null)}
                    placeholder={"Where will you go?"}
                    width="w-full"
                    nullable={false}
                  />

                  <button
                    className="btn-primary w-full"
                    onClick={() => {
                      const obj = {};
                      if (!newSearch.municipality) return toast.error("Please select a municipality");
                      if (newSearch.municipality) obj.municipality = newSearch.municipality;
                      const createSearch = createSearchParams(obj);
                      navigate({
                        pathname: "/",
                        search: createSearch.toString(),
                      });
                    }}>
                    Search
                  </button>
                </div>
              </div>
            )}
            {searches.length > 0 && (
              <div className="flex flex-col w-full lg:w-fit gap-2 shadow py-5 px-3 bg-primary-50 rounded-2xl mb-5">
                <div className="flex gap-2">
                  <div>📢</div>
                  <span className="mb-2">
                    With your current alerts, you can expect to receive <span className="font-semibold">{estimateMatchesAWeek} matches </span>
                    per week.
                  </span>
                </div>

                <div className="flex gap-2">
                  <div>💡</div>
                  <span>
                    Make sure you watch your e-mail / WhatsApp closely. All matches will be emailed to you and you should always respond as soon as possible on those mails.
                  </span>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-5">
              {searches.map((search, index) => {
                return (
                  <div key={index} className="w-full flex flex-col justify-between border p-3 md:p-4 rounded-2xl bg-white shadow gap-3">
                    <div className="flex justify-between items-start md:items-center">
                      <div className="flex flex-col md:flex-row md:items-end justify-start gap-x-4 gap-y-1">
                        <span className="leading-none text-xl font-medium text-primary">
                          {search.name ? <>{search.name}</> : <>{capitalizeFirstLetter(search.municipality)}</>}
                        </span>
                        <span className="text-sm flex justify-start items-center gap-1 text-gray-700">
                          <AiOutlineFieldTime size={17} /> {capitalizeFirstLetter(search.notification_frequency)} {search.notification_type.toLowerCase()} alerts
                        </span>
                      </div>
                      <ActionsMenu
                        actions={[
                          {
                            label: (
                              <>
                                <FaRegEdit size={18} className="text-primary cursor-pointer" /> Edit
                              </>
                            ),
                            callback: () => {
                              setEditSearch(search);
                              Mixpanel.track("home_edit_search_click");
                            },
                          },
                          {
                            label: (
                              <>
                                <FiSettings size={18} className="text-primary cursor-pointer" /> Alerts
                              </>
                            ),
                            callback: () => {
                              setEditSearchAlert(search);
                              Mixpanel.track("home_alert_edit_search_click");
                            },
                          },
                          {
                            label: (
                              <>
                                <RiDeleteBin6Line size={18} className="text-primary cursor-pointer" /> Delete
                              </>
                            ),
                            callback: async () => {
                              if (!window.confirm("Are you sure you want to delete this search?")) return;
                              await removeSearch(search._id);
                              fetchSearches();
                            },
                          },
                        ]}
                      />
                    </div>
                    <div>
                      <div className="text-sm">
                        <p>
                          {search.rental_min || 0}-{search.rental_max}€
                        </p>
                        {search.surface_min && <p>+{search.surface_min}m²</p>}
                        {search.min_bedrooms && <p>+{search.min_bedrooms} bedrooms</p>}
                        {search.min_rooms && <p>+{search.min_rooms} rooms</p>}
                        {search.rental_length_in_weeks_min ? (
                          <p>
                            +
                            {search.rental_length_in_weeks_min <= 4
                              ? search.rental_length_in_weeks_min + " weeks"
                              : (search.rental_length_in_weeks_min / 4.33).toFixed(0) + " months"}
                          </p>
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2 mt-5">
                        <div className="flex flex-wrap gap-3">
                          {search?.types.map((type, i) => {
                            let color = "bg-gray-200";
                            if (type === "ROOM") color = "bg-blue-200";
                            if (type === "STUDIO") color = "bg-green-200";
                            if (type === "APARTMENT") color = "bg-yellow-200";
                            if (type === "ANTI-SQUAT") color = "bg-red-200";
                            return (
                              <span key={i} className={`px-2 py-1 rounded text-xs ${color}`}>
                                {capitalizeFirstLetter(type)}
                              </span>
                            );
                          })}
                        </div>
                        <div className="flex flex-wrap gap-3">
                          {search?.furnished_types.map((type, i) => {
                            let color = "bg-gray-200";
                            if (type === "FURNISHED") color = "bg-blue-200";
                            if (type === "UNFURNISHED") color = "bg-green-200";
                            return (
                              <span key={i} className={`px-2 py-1 rounded text-xs ${color}`}>
                                {capitalizeFirstLetter(type)}
                              </span>
                            );
                          })}
                        </div>
                        <div className="text-sm flex flex-col">
                          {search?.included_paid_sources.length > 0 && (
                            <>
                              <span className="font-medium">Included paid sites:</span>
                              {search.included_paid_sources.map((source, i) => {
                                return (
                                  <span key={i} className={`text-sm`}>
                                    - {SOURCES_TO_READABLE[source]}
                                  </span>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex justify-between items-center gap-2 mt-5">
                      <button
                        className="btn-secondary"
                        onClick={() => {
                          const obj = {};
                          if (search.municipality) obj.municipality = search.municipality;
                          if (search.search_area_geometry) obj.search_area_geometry = JSON.stringify(search.search_area_geometry);
                          if (search.rental_min) obj.rental_min = search.rental_min;
                          if (search.rental_max) obj.rental_max = search.rental_max;
                          if (search.types) obj.types = search.types;
                          if (search.surface_min) obj.surface_min = search.surface_min;
                          if (search.min_bedrooms) obj.min_bedrooms = search.min_bedrooms;
                          if (search.min_rooms) obj.min_rooms = search.min_rooms;
                          if (search.rental_length_in_weeks_min) obj.rental_length_in_weeks_min = search.rental_length_in_weeks_min;
                          if (search.furnished_types) obj.furnished_types = search.furnished_types;
                          if (search.included_paid_sources) obj.included_paid_sources = search.included_paid_sources;
                          if (search.balcony_has) obj.balcony_has = true;
                          if (search.parking_has) obj.parking_has = true;
                          if (search.garden_has) obj.garden_has = true;
                          if (search.garage_has) obj.garage_has = true;
                          if (search.pets_allowed) obj.pets_allowed = true;
                          if (search.registration) obj.registration = true;
                          if (search._id) obj._id = search._id;
                          const createSearch = createSearchParams(obj);
                          navigate({
                            pathname: "/",
                            search: createSearch.toString(),
                          });
                        }}>
                        <span className="text-sm md:text-base">Show {totalMatchesForSearches[search._id]} matches</span>
                      </button>
                      <div className="flex items-center gap-2 justify-start">
                        <Toggle
                          value={search.isActivated}
                          onChange={async (e) => {
                            if (!e && !window.confirm("Are you sure you want to turn off alerts for this search?\nYou will no longer receive email notifications for this alert"))
                              return;
                            const newSearches = searches.map((s, i) => {
                              if (i === index) {
                                return { ...s, isActivated: e };
                              }
                              return s;
                            });
                            const res = await api.put("/user/saved-search/" + search._id, { isActivated: e });
                            if (!res.ok) throw new Error("Error");
                            setSearches(newSearches);
                            dispatch(setUser(res.data));
                            toast.success("Alerts turned " + (e ? "on" : "off"));
                          }}
                        />
                        <span className="hidden md:flex">{search.isActivated ? "Turn off alerts" : "Turn on alerts"}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Searches;
