import * as turf from "@turf/turf";

const createGeoJSON = (data) => {
  if (data.length === 0) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }
  const features = data.map((item) => {
    const { geo_location, ...properties } = item;
    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: geo_location.coordinates,
      },
      properties,
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
};

const fitBounds = (map, geojsonAdverts, removePaddings = false) => {
  if (geojsonAdverts.features.length === 0) return;
  map.fitBounds(
    turf.bbox(geojsonAdverts),
    removePaddings
      ? {}
      : {
          padding: 50,
        },
  );
};

const createBoxGeometry = (coordinates) => {
  const { west, south, east, north } = coordinates;

  return turf.polygon([
    [
      [parseFloat(west), parseFloat(south)],
      [parseFloat(east), parseFloat(south)],
      [parseFloat(east), parseFloat(north)],
      [parseFloat(west), parseFloat(north)],
      [parseFloat(west), parseFloat(south)],
    ],
  ]).geometry;
};

export { createBoxGeometry, createGeoJSON, fitBounds };
