import React, { useEffect, useState } from "react";

import Survicate from "@survicate/survicate-web-package/survicate_widget";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import Loader from "@/components/Loader";
import Navbar from "@/components/navbar/Navbar";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { Mixpanel } from "@/services/mixpanel";
import { Statsig } from "@/services/statsig";

const PublicLayout = () => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(user ? false : true);
  const token = new URLSearchParams(window.location.search).get("token");
  const userId = new URLSearchParams(window.location.search).get("user_id");
  const clientReferenceIdPromoteKit = new URLSearchParams(window.location.search).get("client_reference_id");

  useEffect(() => {
    async function fetchData() {
      try {
        if (token) {
          api.setToken(token);
          const res = await api.get("/user/signin_token_login_as");
          setLoading(false);
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED_LOGIN_AS");
          } else {
            dispatch(setUser(null));
          }
        } else if (userId) {
          const res = await api.post("/user/login-with-id/" + userId, {
            clientReferenceIdPromoteKit: clientReferenceIdPromoteKit,
          });
          setLoading(false);
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Statsig.updateUserSync({ userID: res.data.user._id, email: res.data.user.email, custom: res.data.user });
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED_LOGIN_WITH_ID");
            Survicate.setVisitorTraits({
              first_name: res.data.user.firstname,
              last_name: res.data.user.lastname,
              ...res.data.user,
            });
            Survicate.retarget();
          } else {
            dispatch(setUser(null));
          }
        } else {
          const res = await api.get("/user/signin_token");
          if (res.ok) {
            dispatch(setUser(res.data.user));
            Mixpanel.identify(res.data.user._id);
            Statsig.updateUserSync({ userID: res.data.user._id, email: res.data.user.email, custom: res.data.user });
            Mixpanel.people.set({ ...res.data.user, $email: res.data.user.email, $name: res.data.user.firstname + " " + res.data.user.lastname });
            Mixpanel.track("SUCCESSFULLY_LOADED");
            Survicate.setVisitorTraits({
              first_name: res.data.user.firstname,
              last_name: res.data.user.lastname,
              ...res.data.user,
            });
            Survicate.retarget();
            setLoading(false);
          } else {
            dispatch(setUser(null));
          }
        }
      } catch (e) {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <div className="flex w-full flex-col">
        <div className="flex flex-1 flex-col">
          <Navbar />
          <div className="flex w-full flex-col min-h-[calc(100vh-var(--header-height)-var(--bottom-bar-height))]">
            {/* padding for menu mobile */}
            <div className={`flex-1 md:pb-0 ${user ? "pb-[var(--bottom-bar-height)]" : "pb-0"} `}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicLayout;
